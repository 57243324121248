import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../layout/Layout';
import SEO from '../../components/Seo';
import Nav from '../../components/Nav/Nav';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";



const Mentions = ({ data }) => {

  const data_mentions_legal = data.allContentfulMentionsLegalPage.edges[0].node;

  return (
    <Layout>
      <SEO
        lang="fr"
        title='Publicis et Nous'
        description='Publicis et Nous'
      />
      <div id="main-wrapper" className="row">
        <Nav />
        <section id="container" className="mentions col-lg-9 col-md-12">
          <div className="project-box">
            <div className="content mentions-legal-content">
              <div className="contact-wrapper">
                <ReactMarkdown children={data_mentions_legal.textContent1.textContent1} rehypePlugins={[rehypeRaw]}></ReactMarkdown>
              </div>
              <div className="h-divider">
                <div className="shadow"></div>
              </div>
            </div>
            <div className="content mentions-legal-content">
              <div>
                <ReactMarkdown children={data_mentions_legal.textContent2.textContent2} rehypePlugins={[rehypeRaw]}></ReactMarkdown>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulMentionsLegalPage {
      edges {
        node {
          textContent1 {
            textContent1
          }
          textContent2 {
            textContent2
          }
          title
          description
        }
      }
    }
  }
`

export default Mentions;
