import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import PublicisLogo from '../../assets/images/publicis-logo.png'
import './Nav.scss';
const Nav = ({ }) => {
  const data = useStaticQuery(graphql`
     query Header {
      allContentfulHeader {
        nodes {
          name
          descriptions {
            childMarkdownRemark {
              html
            }
          }
          nav{
            linkUrl
            text
          }
        }
      }
     }
   `)
  

  const [showNavMobile, setShowNavMobile] = useState(false)
  const headerData = data?.allContentfulHeader?.nodes[0];
  let header_description = headerData?.descriptions?.childMarkdownRemark?.html || '';
  header_description = header_description.replace('{name}' , '<a href="/" class="hp-redirect">'+headerData?.name+'</a><a href="/creations" class="hp-redirect-mob">'+headerData?.name+'</a>')

  const onClickOpenBurgerMenu = () =>{
    setShowNavMobile(!showNavMobile);
  }

  return (
    <>
      <nav className={`navbar navbar-expand-lg col-lg-3 col-md-12 ${showNavMobile? 'open' : ''}`}>
          <div className="container-fluid">
            <button className={`navbar-toggler ${showNavMobile? '' : 'collapsed'}`} onClick={onClickOpenBurgerMenu} type="button" data-bs-toggle="collapse" data-bs-target="#navbar-content" aria-expanded="true">
              <div className="hamburger-toggle">
                <div className={`hamburger ${showNavMobile? 'open' : ''}`}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </button>
            <div className={`navbar-collapse collapse ${showNavMobile? 'show' : ''}`} id="navbar-content">
              <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                <div className={`intro-primary ${showNavMobile? 'open' : ''}`}> 
                  <div dangerouslySetInnerHTML={ {  __html: header_description}} />
                </div>
                
                {headerData.nav.map((link, i) => (
                  <li className="nav-item" key={i}>
                    <a href={link.linkUrl} className=" nav-link " aria-current="page">{link.text}</a>
                  </li>
                ))}
                <div className="logo-menu col-lg-12 col-sm-12 ">
                  <Link to={'/'}>
                    <img src={PublicisLogo} className="logo-desk" alt="footer-logo" />
                  </Link>
                  <Link to={"/creations"}>
                    <img src={PublicisLogo} className="logo-mob" alt="footer-logo" /> 
                  </Link>
                </div>
              </ul>
            </div>
          </div>
        </nav>
        <div className={`intro-secondary ${showNavMobile? 'open' : ''}`} dangerouslySetInnerHTML={ {  __html: header_description}}/>
    </>
  );
};

export default Nav;
