import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

import ImgShare from '../assets/images/fb-share-default.jpg';

const SEO = ({ title, description, image, article, lang }) => {
  const { pathname } = useLocation();
  const defaultImage = ImgShare;
  const seoData = {
    title: title || "Default Title",
    description: description || "Description default",
    image: image || `${process.env.SITE_URL}${defaultImage}`,
    url: `${process.env.SITE_URL}${pathname}`,
    lang: lang || "fr",
    twitterUsername: "@EverydayContent"
  };

  return (
    <Helmet
      title={seoData.title}
      htmlAttributes={{ lang: seoData.lang }}
    >
      <meta name="description" content={seoData.description} />
      <meta name="image" content={seoData.image} />

      {seoData.url && <meta property="og:url" content={seoData.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seoData.title && <meta property="og:title" content={seoData.title} />}

      {seoData.description && (
        <meta property="og:description" content={seoData.description} />
      )}

      {seoData.image && <meta property="og:image" content={seoData.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {seoData.twitterUsername && (
        <meta name="twitter:creator" content={seoData.twitterUsername} />
      )}

      {seoData.title && <meta name="twitter:title" content={seoData.title} />}

      {seoData.description && (
        <meta name="twitter:description" content={seoData.description} />
      )}

      {seoData.image && <meta name="twitter:image" content={seoData.image} />}

    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

